import { useEffect, useState, useRef } from "react";

import { Grid, Box, Dialog } from "@mui/material";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { Loading } from "../components/Loading";
import useFetch from "../hooks/useFetch";
import "./Waveforms/Waveforms.css";
import { ServerProblem } from "./ReRoute/ServerProblem";
import DeviceService from "../services/DeviceService";
import { LiveUnit } from "./Home/Live/LiveUnit";
import DeviceMonitor from "../components/device/DeviceMonitor";
import {SelectParameters} from "../components/device/SelectParameters";
import TrendUnit from "./Home//Trend/TrendUnit";
import { isBrowser } from "react-device-detect";
import { parmMap } from "./Home/LiveDashboard";
import { useExternalScript } from "../hooks/useExternalScript";
import { EventTable } from "../components/EventTable"
import CardMedia from '@mui/material/CardMedia';
import { Intervention } from "../components/chart/interfaces";


export interface Event {
	timestamp: Date,
	eventType: string,
	description: string,
	eventData: string
	intervention_id: string;
  }
  

const openPDF = (base64String: string) => {
    // This will convert your base64 string into a byte array
    const binaryString = window.atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a new Blob object using the byte array and specify it as a PDF
    const file = new Blob([bytes.buffer], { type: 'application/pdf' });

    // Create a new object URL for the blob
    const fileURL = URL.createObjectURL(file);

    // Open the object URL in a new tab.
    window.open(fileURL, '_blank');
};



export const WaveMonitor = () => {
	//console.log ("Wavemonitor Renders...")
	const ALL_PARAMETERS = [
		"HR",
		"SpO2",
		"IBP1",
		"IBP2",
		"NIBP",
		"Resp",
		"Temp",
	];
	const params = useParams();
	const [cookies] = useCookies(["access_token"]);
	const [selectableParameters] = useState<string[]>([]);
	const isPharlap = localStorage.getItem("SYSTEM_TYPE") === "PHARLAP";
    const [open, setOpen] = useState(false);
	const [imageData, setImageData] = useState<string | null>(null);
	const [loadingImage, setLoadingImage] = useState(false);  // Create a new state variable for the loading state
	const [availableWaveforms, setAvailableWaveforms] = useState <string []> ([])
    const [selectedIntervention, setSelectedIntervention] = useState<string>('');
    const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
	const refreshInProgress = useRef (false)
    const [interventions, setInterventions] = useState<Intervention[]>([]);
	const [liveIntervention, setLiveIntervention] = useState<string>('');

	const triggerRefresh = (intervention_id: string) => {
		if (intervention_id === '' || refreshInProgress.current) {
			return;
		}
		console.log ("REFRESH HAS BEEN TRIGGERED for ", intervention_id)
		// If we can't find the intervention_id in the list, we want to refresh the list
		if (!interventions.some(intervention => intervention.intervention_id === intervention_id)) {
			refreshInProgress.current = true;
			setRefreshTrigger(prev => prev + 1); // Increment the trigger value to force a refresh
		}
    };




	const displayBase64ImageInDialog = (base64Image: string) => {
		console.log ("base64 data:", base64Image)
        // Adding metadata if it's not present
        let imgdata = base64Image.startsWith('data:image/png;base64,') 
            ? base64Image 
            : 'data:image/png;base64,' + base64Image;
        
        setImageData(imgdata);
    };

    const handleClose = () => {
        setOpen(false);
    };

	const addEvent = (event: Event) => {
		//console.log ("addEvent:", event)
		switch (event.eventType) {
			case "alarm" : {
				setAlarmList (prevAlarms => [event, ...prevAlarms]);
				break;
			}
			case "event" : {
				setEventList(prevEvents => {
					if (prevEvents.some(e => e.timestamp.getTime() === event.timestamp.getTime())) {
						return prevEvents; // Return the existing list if event is found
					}
					const updatedEvents = [event, ...prevEvents];
					updatedEvents.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
					return updatedEvents;
				  });
				

				//console.log ("Event received:", event)
				break;
			}
			case "ecg" : 
			case "restingecg":
			{
				setEcgList(prevEcgs => {
					if (prevEcgs.some(e => e.timestamp.getTime() === event.timestamp.getTime())) {
						return prevEcgs; // Return the existing list if event is found
					}
					const updatedEcgs = [event, ...prevEcgs];
					updatedEcgs.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
					return updatedEcgs;
				  });

				  //setEcgList (prevEcgs => [event, ...prevEcgs]);
				break;
			}

			case "msg" : {
				setMsgList(prevMsgs => {
					if (prevMsgs.some(e => e.timestamp.getTime() === event.timestamp.getTime())) {
						return prevMsgs; // Return the existing list if event is found
					}
					const updatedEvents = [event, ...prevMsgs];
					updatedEvents.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
					return updatedEvents;
				  });
				break;
			}

			case "image" : {
				setImageList(prevImages => {
					if (prevImages.some(e => e.timestamp.getTime() === event.timestamp.getTime())) {
						return prevImages; // Return the existing list if event is found
					}
					const updatedEvents = [event, ...prevImages];
					updatedEvents.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
					return updatedEvents;
				  });
				break;
			}

		} // switch
	  }; // addEvent
	
	  const [alarmList, setAlarmList] = useState<Event[]>([]);
	  const [eventList, setEventList] = useState<Event[]>([]);
	  const [ecgList,   setEcgList]   = useState<Event[]>([]);
	  const [msgList,   setMsgList]   = useState<Event[]>([]);
	  const [imageList, setImageList] = useState<Event[]>([]);

	  const [interventionAlarmList, setInterventionAlarmList] = useState<Event[]>([]);
	  const [interventionEventList, setInterventionEventList] = useState<Event[]>([]);
	  const [interventionEcgList,   setInterventionEcgList]   = useState<Event[]>([]);
	  const [interventionMsgList,   setInterventionMsgList]   = useState<Event[]>([]);
	  const [interventionImageList, setInterventionImageList] = useState<Event[]>([]);

	const handleEventSelected = (event: Event) => {
	  // Do something with the selected event
	  console.log(event);
	  switch (event.eventType) {
		case "ecg" : {
			const evData = JSON.parse (event.eventData);
			openPDF (evData["data"])
			break;
		}

		case "image" : {
			retrieveAndOpenImage (event);
			break;
		}
	  }
	};
  

	const retrieveAndOpenImage = async (event: any) => {
		try {
			setOpen(true);

			setLoadingImage(true);  // Set loading state to true when the request starts
			const eventData = JSON.parse (event.eventData);
			const url = `${DeviceService.getImage(device.device_id)}?timestamp=${encodeURIComponent(eventData.unique_id)}`;

			const response = await fetch(url, {
				headers: {
				  'Authorization': `Bearer ${cookies.access_token}`
				}
			});
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const fetchImageData = await response.json();

			const imgData = JSON.parse (fetchImageData["data"]);
			const img2 = imgData["data"]
			const imageString = "data:image/png;base64," + img2["image"]
			setLoadingImage(false);  // Set loading state to false when the request is finished

			displayBase64ImageInDialog(imageString)
	
		} catch (error) {
			setLoadingImage(false);  // Also set loading state to false in case of an error
			setOpen(false);

			console.error(error);
		}
	};
	
  
	// @ts-ignore
	const deviceId: string = params["deviceId"];
	const savedParams = JSON.parse(
		// @ts-ignore
		localStorage.getItem("wavemonitor-" + deviceId)
	);
	const [selectedParameters, setSelectedParameters] = useState<string[]>(
		savedParams || ALL_PARAMETERS
	);

	const {
		data: device,
		loading,
		error,
	} = useFetch({
		url: DeviceService.getDevice(),
		// @ts-ignore
		id: deviceId,
		access_token: cookies.access_token,
	});


	const {
		data: eventData,
		loading: eventLoading,
		error: eventError,
	} = useFetch({
		url: DeviceService.getEventData(deviceId),	
		// @ts-ignore
		access_token: cookies.access_token,
	});


	const state = useExternalScript("../ivideon/iv-standalone-web-sdk.js");

	useEffect(() => {
		//console.log ("Selected intervention:", selectedIntervention)

		// Filter lists based on selectedIntervention
		const filteredAlarmList = alarmList.filter(item => item.intervention_id === selectedIntervention);
		const filteredEventList = eventList.filter(item => item.intervention_id === selectedIntervention);
		const filteredEcgList = ecgList.filter(item => item.intervention_id === selectedIntervention);
		const filteredMsgList = msgList.filter(item => item.intervention_id === selectedIntervention);
		const filteredImageList = imageList.filter(item => item.intervention_id === selectedIntervention);

		// Set the filtered lists to the corresponding state
		setInterventionAlarmList(filteredAlarmList);
		setInterventionEventList(filteredEventList);
		setInterventionEcgList(filteredEcgList);
		setInterventionMsgList(filteredMsgList);
		setInterventionImageList(filteredImageList);
		
	}, [selectedIntervention, alarmList, eventList, ecgList, msgList, imageList])

	useEffect(() => {
		//console.log ("event data:", eventData)
		if (eventData === null) {
			return;
		}

		for (var i = 0; i < eventData.length; i++) {
			const item = eventData[i];
			const msg = JSON.parse (item["data"]);

			const intervention_id: string = msg.intervention_id;
			if (selectedIntervention && intervention_id !== selectedIntervention) {	
				//continue;
			}

			const eventType = msg["event_type"];
			//console.log ("Considering:", eventType)
			if (eventType === "msg") {
				//console.log ("Retrieved event:", msg)
				var event_m: Event = {
					timestamp: new Date(msg["timestamp"]),
					eventType: "msg",
					description: msg["event_data"],
					eventData: "",
					intervention_id: msg.intervention_id
				};
				addEvent(event_m);	
			}

			if (eventType === "defib") {
				const event_data = JSON.parse (msg["event_data"])
				var datastr: string = event_data["Description"];
				var event_e: Event = {
					timestamp: new Date(msg["timestamp"]),
					eventType: "event",
					description: datastr,
					eventData: "",
					intervention_id: msg.intervention_id
				};
				addEvent(event_e);	
			}

			if (eventType === "restingecg") {

				const evData: string = JSON.stringify({
					type: "pdf",
					data: msg["event_data"],
				});
				var descStr = "Resting ECG received";
				var ecg_e: Event = {
					timestamp: new Date(msg["timestamp"]),
					eventType: "ecg",
					description: descStr,
					eventData: evData,
					intervention_id: msg.intervention_id
				};
				addEvent(ecg_e);

			}
			if (eventType === "image") {

				var event_i: Event = {
					timestamp: new Date(msg["timestamp"]),
					eventType: "image",
					description: msg["fileName"],
					eventData: JSON.stringify ({
						"unique_id" : msg["unique_id"],
						"thumbnail" : msg["thumbnail"]
					}),
					intervention_id: msg.intervention_id
				};
				addEvent(event_i);
			}

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventData])

	useEffect(() => {
		if (state === "ready" && device) {
			// @ts-ignore
			_ivideon.sdk
				.init({
					rootUrl: "../../ivideon/",
					l10nOptions: {
						availableLanguages: ["de", "en", "fr"],
						language: "en",
					},
				})
				.then(
					function (sdk: any) {
						sdk.configureWithCloudApiAuthResponse({
							api_host: "openapi-alpha.ivideon.com",
							access_token: device.camera_token,
						});

						var camera = sdk.createCamera({
							id: device?.camera_id,
							cameraName: "Pet Cam",
							imageWidth: 800,
							imageHeight: "123px",
							soundEnabled: false,
						});

						var player = sdk.createPlayer({
							container: "#ivideon_stream",
							camera: camera,
						        sizeCalculationMethod : sdk.playerSize.FILL_BY_WIDTH,
							"timelineEnabled" : false,
							"playPauseButtonEnabled" : false,
							"goToLiveButtonEnabled" : false,
							"volumeControlEnabled" : false,
							"qualityControlEnabled" : false,
							"speedControlEnabled" : false,
							"previewEnabled" : false,
							"previewFromCameraEnabled" : false,
						        layout: "expanded"
						});

						player.playLive({ quality: 2 });
					},
					function (error: any) {
						console.error(error);
					}
				)
				.catch((error: any) => {
					console.error(error);
				});
		}

		if (device !== null) {
			// Extract the device def
			const devJson = JSON.parse (device.device_def)
			const waveforms = devJson.waveforms
			if (waveforms) {
				var waveformList = Object.keys (waveforms)

				/*
				 * This is a workaround for LifeSignals patch, remove ECG_V5
				 */
					const target = 'ECG_V5';
					waveformList = waveformList.filter(item => item !== target);
				/*
				 * Workaround END
				 */
	
				setAvailableWaveforms (waveformList)	
			}

		}
		
	}, [state, device]);

    useEffect(() => {
        let isMounted = true;

        if (!device?.device_id) {
            return;
        }
        const d = { device_id: device.device_id };

        DeviceService.getDeviceInterventions(d, cookies.access_token).then((res: any) => {
            if (isMounted) {
				const inters = res?.data
                setInterventions(inters);
				if (inters.length > 0) {
						setLiveIntervention(inters[inters.length - 1].intervention_id as string);
				}
            }
			refreshInProgress.current = false;

        });

        return () => {
            isMounted = false;
        };
    }, [device, cookies.access_token, refreshTrigger]);



	if (eventLoading || loading || device === null) return <Loading />;

	if (error || eventError) {
		return <ServerProblem />;
	}


	const availableParameters = parmMap[device.device_model].parms;

	return (
		<Grid container spacing={1} style={{marginTop: 0}}>

			<Grid item xs={8} style={{display: 'flex', flexDirection: 'column'}}>

				<Box sx={{ height: isBrowser ? 450 : 225 }}>
					<LiveUnit
						key={deviceId}
						device={device}
						width={12}
						type="livewaveforms"
						availableParameters={availableParameters}
						availableWaveforms={availableWaveforms}
						header={true}
						minHeight={isBrowser ? 400 : 150}
						parameterBox={false}
						useLink={false}
						showWaveform={true}
						hideCamera={true}
						addEvent={addEvent}
						showDeviceInfo={false}
						selectedIntervention={selectedIntervention} // The intervention to be displayed
						setSelectedIntervention={setSelectedIntervention} // callback to set the selected intervention
                        triggerRefresh={triggerRefresh}	// Callback to trigger a refresh of the intervention list.
						liveIntervention={liveIntervention}
					/>
				</Box>
				<Box>
					<TrendUnit
						key={"trend_" + device.device_id}
						device={device}
						hideTrend={false}
						type="wavemonitor-trend"
						useLink={false}
						connectStatus="undefined"
						fullWidth={true}
						intervention_id={selectedIntervention}
						showDeviceInfo={false}
						showChartIcon={false}
						showConnectionStatus={false}
						showDeviceName={false}
						showPatientInfo={false}
						showEkgRecording={false}
						showSettings={true}

					/>

				</Box>
			</Grid>

			<Grid item xs={4} style={{ display: "flex", flexDirection: "column" }}>
				<Box>
					<DeviceMonitor
						device={device}
						fullWidth={true}
						type={"wavemonitor"}
						useLink={false}
						availableParams={availableParameters}
						selectParams={
							<SelectParameters
								deviceId={device.device_id}
								selectableParameters={selectableParameters}
								selectedParameters={selectedParameters}
								setSelectedParameters={setSelectedParameters}
								maxParameters={selectableParameters.length}
								type="wavemonitor"
							/>
						}
						selectedIntervention={selectedIntervention}
						setSelectedIntervention={setSelectedIntervention}
						interventions={interventions}
						showInterventionSelector={interventions.length > 0}
						showConnectStatus={false}
						liveIntervention={liveIntervention}
					/>
				</Box>

				
				 <Box style={{flexGrow: 1, height:80, overflowY: 'auto'}}>
				 { !isPharlap && <EventTable
						alarmList={interventionAlarmList}
						eventList={interventionEventList}
						msgList={interventionMsgList}
						imageList={interventionImageList}
						ecgList={interventionEcgList}
						videoId={device.camera_id}
						onEventSelected={handleEventSelected}
						style={{ flex: 1, overflowY: "auto" }}
						device = { device }
						
					/>
				}
				<Dialog open={open} onClose={handleClose} >
					{loadingImage 
						? <div><h1>Downloading image...</h1></div>
						: imageData && <CardMedia component="img" image={imageData} alt="Base64" style={{maxWidth: '100%', height: 'auto'}} />
					}
				</Dialog>
				{device && state === "ready" && (
					<div
						className="myapp-player-container"
						id="ivideon_stream"
						style={{
							width: "100%",
							height: "123px",
							marginLeft: 4,
							marginRight: 4,
							marginTop: isBrowser ? 0 : 40,
							marginBottom: isBrowser ? 0 : 120,
						}}
					></div>
				)}
				</Box>
			</Grid>
		</Grid>
	);
	
	
	
};
