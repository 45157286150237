import React from "react";
import playstore from "./playstore.png";
import appstore from "./appstore.png";
import app from "./app.png";

export const Deeplink: React.FC = () => {
  const handleDeeplink = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const appStoreUrl = isIOS
      ? "https://apps.apple.com/us/app/your-app-id"
      : "https://play.google.com/store/apps/details?id=your.app.id";

    window.location.href = appStoreUrl;
  };
  // Try to redirect to the deeplink
  const redirect = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    // Get the code from the URL
    const code = new URLSearchParams(window.location.search).get("code");
    console.log(code);

    const appStoreUrl = "infiniwellrpm://link?key=" + code;

    window.location.href = appStoreUrl;
  };
  redirect();
  const APKUrl =
    "https://play.google.com/store/apps/details?id=ai.infiniwell.obelix";
  const IOSUrl = "https://apps.apple.com/us/app/infiniwellrpm/6504235578";

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {window.innerWidth > window.innerHeight && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            width: "20%",
          }}
        >
          <img
            src={app}
            alt="A screenshot of the app"
            style={{ width: "100%", marginLeft: "100px" }}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",

          flex: 3,
          flexDirection: "column",
          top: 50,
        }}
      >
        <h1>The Infiniwell RPM app</h1>
        <p style={{ fontSize: "1.5em" }}>
          Click one of the buttons below to get the app
        </p>
        <div
          style={{
            display: "flex",
            maxWidth: "400px",
            minWidth: "100px",
            margin: "20px",
            flex: 1,
          }}
        >
          <a href={APKUrl} target="_blank">
            <img
              style={{
                flex: 1,
                maxWidth: "90%",
                height: "auto",
              }}
              src={playstore}
              alt="Google Play"
            />
          </a>

          <a href={IOSUrl} target="_blank" rel="noreferrer">
            <img
              style={{ flex: 1, maxWidth: "90%", height: "auto" }}
              src={appstore}
              alt="App Store"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
